import { Component, Injectable} from '@angular/core';
import { Router } from '@angular/router';
import {Idle, DEFAULT_INTERRUPTSOURCES} from '@ng-idle/core';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
@Injectable({
  providedIn: 'root'
})
export class AppComponent {
  title = 'sdg-frontend';
  idleState = 'Not started.';
  timedOut = false;
  toast: any ;
  toastComponent: any ;

  constructor(private idle: Idle, private route: Router, private toastrService: ToastrService) {

    const onLoginPage = window.location.pathname === '/' || window.location.pathname === '/login'

    // sets an idle timeout of 840 seconds (14 mins)
    idle.setIdle(840);

    // sets a timeout period of 60 seconds. after 15 minutes of inactivity, the user will be considered timed out.
    idle.setTimeout(60);
    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    idle.onIdleEnd.subscribe(() => {
      this.idleState = 'No longer idle.'
      this.toastrService.clear()
      // console.log("no longer idle")
    });
    idle.onTimeout.subscribe(() => {
      this.idleState = 'Timed out!';
      this.timedOut = true;

      // console.log("timed out!")

      if (!onLoginPage) {
        this.logout()
        this.toastrService.clear()
      }

    });
    idle.onIdleStart.subscribe(() => {
      this.idleState = 'You\'ve gone idle!'

      if (!onLoginPage) {
        this.toast = this.toastrService.warning(this.idleState,'',{timeOut: 0, closeButton: false});
        this.toastComponent = this.toast.toastRef.componentInstance;
      }
      // console.log("you've gone idle")
    });
    idle.onTimeoutWarning.subscribe((countdown) => {
      if (!onLoginPage) {
        this.idleState = 'You you will be signed out in ' + countdown + ' seconds!'
        this.toastComponent.message = 'You will be signed out in ' + countdown + ' seconds!'
      }
      // console.log(`You be signed out in ${countdown} seconds `)
    });

    this.reset()
  }

  logout() {
    localStorage.removeItem('token')
    localStorage.removeItem('userData')
    this.route.navigateByUrl('/login');
  }

  reset() {
    this.idle.watch();
    this.idleState = 'Started.';
    this.timedOut = false;
  }
}
